
import { defineComponent } from "vue-demi";
import { TARIFF_TABLE_OPTIONS } from "@/store/common/default/table";
import CommonTable from '@/components/common/table/index.vue';

export default defineComponent({
  name: 'TariffList',
  data() {
    return {
      tariffs: TARIFF_TABLE_OPTIONS()
    }
  },
  components: {
    CommonTable
  }
})
