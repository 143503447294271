
import { defineComponent } from "vue-demi";
import Tariff from "@/components/tariff/List.vue";

export default defineComponent({
  name: "TariffListView",
  data() {
    return {
      tariffs: [],
    }
  },
  created() {
    this.getListMethod();
  },
  methods: {
    async getListMethod() {
      // const result = await useAPI().tariff.getListMethod();
      return [];
    }
  },
  components: {
    Tariff,
  },
});
